
export enum LimitValue {
    MIN_ANGLE = 40,
    MIN_SIDE_LENGTH = 6,
    MIN_CIRCLE_RADIUS = 23.5
}

export interface Limit{
    min_angle: number;    
    min_side_length: number;
    min_circle_radius: number;
}

export const initLimit = (): Limit =>({
    min_angle: LimitValue.MIN_ANGLE,    
    min_side_length: LimitValue.MIN_SIDE_LENGTH,
    min_circle_radius: LimitValue.MIN_CIRCLE_RADIUS
})