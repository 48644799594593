import {RenderData, resetRenderData} from "../../render/render_components/render_data/render_data";
import {ConstrainValues} from "../../constraints/constrain_value";
import { constrainLayout } from "../../constraints/functions_constrain";

//Handle when a header button is clicked or selected
export const handleHeaderButton = (buttonName: string, renderData: RenderData, constrainValues: ConstrainValues) =>{
    clearConfigurator(renderData);
    switch(buttonName){
        case "Stack/Mull":
        case "XOP":        
        case "Window":        
        case "Patio Door":            
            renderData.all_window.layout = buttonName;
            constrainLayout(buttonName, constrainValues);
        break;        
    }
}

//Clear the configurator
const clearConfigurator = (renderData: RenderData) =>{        
    resetRenderData(renderData);
}