import {ConstrainValues} from "./constrain_value";
import {setControl, setVisibleControl} from "./functions_constrain";
import {RenderData} from "../render/render_components/render_data/render_data";
import {validateWindows} from "./functions_validate_window";
import {disableShapeWindow} from "./functions_shape_constrain";
import {WindowFragment} from "../render/render_components/window/window_fragment";
import {Segment} from "../render/render_components/render_data/enum_render";


//Constrain values after a split has been triggered
export const constrainSplit = (renderData: RenderData, constrainValues: ConstrainValues) =>{
    validateWindows(constrainValues, renderData);
    const window = renderData.window[renderData.active_fragment_id];
    disableShapeWindow(window, renderData, constrainValues);

    resetXOPControls(constrainValues);
    constrainXOPControls(window, renderData, constrainValues);
}

//Constrain values after a dimension change has been triggered
export const constrainXOPDimension = (constrainValues: ConstrainValues, renderData: RenderData) =>{
    validateWindows(constrainValues, renderData);
}

//Constrain values based on if the XOP properties is checked or not
export const constrainAdvanced = (value: string, constrainValues: ConstrainValues) =>{    
    const control = constrainValues.control;

    if(value === "true"){
        setControl(control.xop_advanced, false, false);
        setControl(control.xop_top, false, false);
        setControl(control.xop_bottom, false, false);
        setControl(control.xop_left, false, false);
        setControl(control.xop_right, false, false);
    }
    else{
        setControl(control.xop_advanced, true, true);
        setControl(control.xop_top, true, true);
        setControl(control.xop_bottom, true, true);
        setControl(control.xop_left, true, true);
        setControl(control.xop_right, true, true);
    }
}

//Constrain XOP controls, based on a window
export const constrainXOPControls = (window: WindowFragment, renderData: RenderData, constrainValues: ConstrainValues) =>{

    //Short hand
    const top = window.model.line[Segment.TOP].y1;
    const bottom = window.model.line[Segment.BOTTOM].y1;
    const left = window.model.line[Segment.LEFT].x1;
    const right = window.model.line[Segment.RIGHT].x1;

    const control = constrainValues.control;

    //If the window spans top to bottom
    if(top ===0 && bottom === renderData.viewport.height){
        setControl(control.xop_height, true, false);        
    }

    //If the window spans left to right
    if(left ===0 && right === renderData.viewport.width){
        setControl(control.xop_width, true, false);        
    }

    //If the window touches the top, bottom, left or right
    if(top === 0){
        setVisibleControl(control.xop_top, true);
    }
    if(bottom === renderData.viewport.height){
        setVisibleControl(control.xop_bottom, true);        
    }
    if(left === 0){
        setVisibleControl(control.xop_left, true);
    }
    if(right === renderData.viewport.width){
        setVisibleControl(control.xop_right, true);
    }
}

//Reset the constrain state of the XOP controls
export const resetXOPControls = (constrainValues: ConstrainValues) =>{
    const control = constrainValues.control;

    setControl(control.xop_width, false, false);
    setControl(control.xop_height, false, false);
    setVisibleControl(control.xop_top, false);
    setVisibleControl(control.xop_bottom, false);
    setVisibleControl(control.xop_left, false);
    setVisibleControl(control.xop_right, false);
}


