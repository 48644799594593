import {RenderData} from "../render_data/render_data";
import {WindowFragment} from "../window/window_fragment";

import {
    maskWindow, 
    maskGrid, 
    maskOpen, 
    maskPost,     
    maskScreen
} from "../mask/mask_standard";
import{
    maskArchWindow,    
    maskStraightArch,
    maskHalfArch,
    maskStraightHalfArch
} from "../mask/mask_arch";
import{
    renderSelectArch,
    renderSelectStandard,
    renderSelectStraightArch,
    renderSelectHalfArch,
    renderSelectStraightHalfArch,
    renderSelectTriangle,
    renderSelectPeak,
    renderSelectRightTriangle,
    renderSelectStraightRightTriangle,
    renderSelectCircle,
    renderSelectOval,
    renderSelectHexagon,
    renderSelectOctagon
} from "./render_select";
import {
    maskDimensionStandard,
    maskDimensionArch,
    maskDimensionStraightArch,
    maskDimensionHalfArch,
    maskDimensionStraightHalfArch,
    maskDimensionTriangle,
    maskDimensionRightTriangle,
    maskDimensionPeak,
    maskDimensionStraightRightTriangle,
    maskDimensionCircle,
    maskDimensionOval,
    maskDimensionHexagon,
    maskDimensionOctagon
} from "../mask/mask_dimension";
import {
    maskPeak,
    maskTriangle,
    maskRightTriangle,
    maskStraightRightTriangle,    
} from "../mask/mask_triangle";
import {
    maskCircle,
    maskOval,
    maskHexagon,
    maskOctagon
} from "../mask/mask_circular";
import {
    maskErrorPost,
    maskErrorSide,
    maskErrorAngle
} from "../mask/mask_error";

//Draw all the various objects from the render data
export const Render = (renderData: RenderData) =>{    
    clear(renderData); //Clear the viewport

    renderNetFrame(renderData); //Render the frame    
    renderFragments(renderData); //Draw all window fragments        

    if(renderData.active_fragment_id >=0){ //If a fragment is selected, render a selection        
        const window = renderData.window[renderData.active_fragment_id];
        //Don't render the window being selected if there is only 1 window and only 1 panel
        if(renderData.window.length > 1 || window.panel.length >1){
            renderSelected(renderData);
        }        
    }

    renderError(renderData);
}

//Clear Viewport
const clear = (renderData: RenderData) =>{
    renderData.viewport.context!.clearRect(
        0, 
        0, 
        renderData.viewport.width, 
        renderData.viewport.height
    );
}

//Render frame elements
const renderNetFrame = (renderData: RenderData) =>{
    const context = renderData.viewport.context;

    context!.fillStyle = renderData.all_window.color_frame;
    context!.fillRect(
        0, 
        0, 
        renderData.viewport.width, 
        renderData.viewport.height
    );

    const offset = renderData.viewport.line_width;
    
    context!.lineWidth = renderData.viewport.line_width;
    context!.strokeStyle = renderData.viewport.line_style;
    context!.strokeRect(
        0 + offset, 
        0 + offset, 
        renderData.viewport.width - (offset *2), 
        renderData.viewport.height - (offset *2)
    );    
}

//Draw the borders of the window fragments
const renderFragments = (renderData: RenderData) =>{
    //Draw all the existing window fragments.

    renderData.window.forEach(window =>{                        
        switch(window.data.product_type.value){
            case "Factory Arch":
                renderArch(renderData, window);
            break;
            case "Straight Leg Arch":
                renderStraightArch(renderData, window);
            break;
            case "R 1/4 Circle":
            case "L 1/4 Circle":
                renderHalfArch(renderData, window);
            break;
            case "L Straight Leg Arch":
            case "R Straight Leg Arch":
                renderStraightHalfArch(renderData, window);
            break;
            case "Peak":
                renderPeak(renderData, window);
            break;
            case "Triangle":
                renderTriangle(renderData, window);
            break;
            case "R Triangle":
            case "L Triangle":
                renderRightTriangle(renderData, window);
            break;
            case "R Rake":
            case "L Rake":
                renderStraightRightTriangle(renderData, window);
            break;
            case "Circle":
                renderCircle(renderData, window);
            break;
            case "Oval":
                renderOval(renderData, window);
            break;
            case "Hexagon":
                renderHexagon(renderData, window);
            break;
            case "Octagon":
                renderOctagon(renderData, window);
            break;
            default:                
                renderStandardWindow(renderData, window);
        }            
    })
}


//Draw an octaon
const renderOctagon = (renderData: RenderData, window: WindowFragment) =>{
    maskOctagon(window, renderData);
    maskDimensionOctagon(renderData, window);
}

//Draw a hexagon
const renderHexagon = (renderData: RenderData, window: WindowFragment) =>{
    maskHexagon(window, renderData);
    maskDimensionHexagon(renderData, window);
}

//Draw an oval
const renderOval = (renderData: RenderData, window: WindowFragment) =>{
    maskOval(window, renderData);
    maskDimensionOval(renderData, window);
}

//Draw a circle
const renderCircle = (renderData: RenderData, window: WindowFragment) =>{
    maskCircle(window, renderData);
    maskDimensionCircle(renderData, window);
}

//Draw a straight arch
const renderStraightArch = (renderData: RenderData, window: WindowFragment) =>{
    maskStraightArch(window, renderData);
    maskDimensionStraightArch(renderData, window);
}

//Draw an arch
const renderArch = (renderData: RenderData, window: WindowFragment) =>{
    maskArchWindow(renderData, window);
    maskDimensionArch(renderData, window);
}

//Draw a half arch
const renderHalfArch = (renderData: RenderData, window: WindowFragment) =>{
    maskHalfArch(window, renderData);
    maskDimensionHalfArch(renderData, window);
}

//Draw a straight half arch
const renderStraightHalfArch = (renderData: RenderData, window: WindowFragment) =>{
    maskStraightHalfArch(window, renderData);
    maskDimensionStraightHalfArch(renderData, window);
}

//Draw a peak
const renderPeak = (renderData: RenderData, window: WindowFragment) =>{    
    maskPeak(window, renderData);
    maskDimensionPeak(renderData, window);
}

//Draw Triangle
const renderTriangle = (renderData: RenderData, window: WindowFragment) =>{
    maskTriangle(window, renderData);
    maskDimensionTriangle(renderData, window);
}

//Draw a right triangle
const renderRightTriangle = (renderData: RenderData, window: WindowFragment) =>{
    maskRightTriangle(window, renderData);
    maskDimensionRightTriangle(renderData, window);
}

const renderStraightRightTriangle = (renderData: RenderData, window: WindowFragment) =>{
    maskStraightRightTriangle(window, renderData);
    maskDimensionStraightRightTriangle(renderData, window);
}

//Draw a standard rectangular window
const renderStandardWindow = (renderData: RenderData, window: WindowFragment) =>{
    //Draw the window mask
    maskWindow(window, renderData);
        
    //Iterate through the panels and draw their masks
    window.panel.forEach(panel =>{
        maskGrid(renderData, window, panel);        
        if(panel.data.has_screen){
            maskScreen(renderData, panel);
        }
        
        maskOpen(panel, renderData);
        maskDimensionStandard(renderData, window, panel);
    })            

    //Draw posts, if the window has any
    if(window.post.length >=0){
        maskPost(window, renderData);
    }                
}

//Highlight a fragment
const renderSelected = (renderData: RenderData) =>{
    const window = renderData.window[renderData.active_fragment_id];

    switch(window.data.product_type.value){
        case "Factory Arch":
            renderSelectArch(window, renderData);
        break;
        case "Straight Leg Arch":
            renderSelectStraightArch(window, renderData);
        break;
        case "L 1/4 Circle":
        case "R 1/4 Circle":
            renderSelectHalfArch(window, renderData);                
        break;
        case "L Straight Leg Arch":
        case "R Straight Leg Arch":
            renderSelectStraightHalfArch(window, renderData);
        break;
        case "Triangle":
            renderSelectTriangle(window, renderData);
        break;
        case "Peak":
            renderSelectPeak(window, renderData);
        break;        
        case "R Triangle":
        case "L Triangle":
            renderSelectRightTriangle(window, renderData);
        break;
        case "R Rake":
        case "L Rake":
            renderSelectStraightRightTriangle(window, renderData);
        break;
        case "Circle":
            renderSelectCircle(window, renderData);
        break;
        case "Oval":
            renderSelectOval(window, renderData);
        break;
        case "Hexagon":
            renderSelectHexagon(window, renderData);            
        break;
        case "Octagon":
            renderSelectOctagon(window, renderData);
        break;        
        default:
            renderSelectStandard(window, renderData);
    }
}

//Draw any errors if they exist
const renderError = (renderData: RenderData) =>{
    
    //Draw errors associated with inline posts
    if(renderData.error.post.model.length >0) maskErrorPost(renderData);

    //Draw errors associated with the sides of the window
    if(renderData.error.side.line.length >0) maskErrorSide(renderData);

    //Draw error associated with angles being too small
    if(renderData.error.angle.vertex.length >0) maskErrorAngle(renderData);
}


