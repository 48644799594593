import {ConstrainValues} from "./constrain_value";
import {setControl} from "./functions_constrain";

//Constrain based on panels aligned horizontally
export const constrainPanelHorizontal = (constrainValues: ConstrainValues) =>{
    const control = constrainValues.control;

    enableGridScreen(constrainValues);
    setControl(control.window_width, false, false);
    setControl(control.window_open_direction, false, false);
}

//Constrain based on panels aligned vertically
export const constrainPanelVertical = (constrainValues: ConstrainValues) =>{
    const control = constrainValues.control;

    enableGridScreen(constrainValues);
    setControl(control.window_height, false, false);
    setControl(control.window_open_direction, false, false);
}

//Constrain windows with legs and a shape, that do not have grids (or screens)
export const constrainStraightNoGrid = (constrainValues: ConstrainValues) =>{
    const control = constrainValues.control;

    setControl(control.window_second_height, false, false);
}

//Constrain windows with legs and a shape, that have a grid (still no screen)
export const constrainStraightGrid = (constrainValues: ConstrainValues) =>{
    const control = constrainValues.control;

    setControl(control.window_second_height, false, false);
    setControl(control.grid, false, false);
}

//Constrain only the grid tab
export const constrainGridOnly = (constrainValues: ConstrainValues) =>{
    const control = constrainValues.control;

    setControl(control.grid, true, true);
}

//Constrain only the window properties
export const constrainWindowOnly = (constrainValues: ConstrainValues) =>{
    const control = constrainValues.control;

    setControl(control.window, true, true);
    enableGridScreen(constrainValues);
}

//Enable grid and screen controls; this is just a shorthand
const enableGridScreen = (constrainValues: ConstrainValues) =>{
    const control = constrainValues.control;

    setControl(control.grid, false, false);
    setControl(control.screen, false, false);
}