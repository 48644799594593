import {RenderData} from "../../render/render_components/render_data/render_data";
import {
    clearViewport,
    SplitHorizontal,
    SplitVertical,
    changeTop,
    changeBottom,
    changeLeft,
    changeRight,
    changeWidth,
    changeHeight

} from "../../render/render_components/viewport/functions_viewport";
import {constrainXOPProperties} from "../../constraints/functions_constrain";
import {ConstrainValues} from "../../constraints/constrain_value";

const XOPProperties = (
    id_object: string,
    object_value: string,     
    renderData: RenderData,
    constrainValues: ConstrainValues
) =>{
    if(id_object === "clear_viewport"){
        
        //************************ Going to need to zero out all the data related to the previous window
        //********* Possibly do it in the clearviewport function; or some other function; whatever sounds fun
        clearViewport(renderData);
    }
    
    switch(id_object){
        case "split_horizontal":
            SplitHorizontal(renderData);            
        break;
        case "split_vertical":
            SplitVertical(renderData);            
        break;                    
        case "input_top":                        
            changeTop(renderData, object_value);            
        break;
        case "input_bottom":
            changeBottom(renderData, object_value);            
        break;
        case "input_left":
            changeLeft(renderData, object_value);            
        break;
        case "input_right":
            changeRight(renderData, object_value);            
        break;
        case "input_width":
            changeWidth(renderData, object_value);            
        break;
        case "input_height":
            changeHeight(renderData, object_value);            
        break;
    }    
    constrainXOPProperties(id_object, object_value, constrainValues, renderData);
}

export default XOPProperties;