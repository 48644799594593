import {useState, useEffect, Fragment, ReactNode} from "react";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import Button from 'react-bootstrap/Button';
import {OrderList} from "../data/order/order_list";
import {OrderDetail} from "../data/order/order_detail";
import {getOrderListCopy} from "../data/copy_data";
import {getOrderString} from "../data/order/functions_detail";

interface Props{
    orderDetail: OrderDetail, 
    itemKey: number, 
    handleRemoveButton: (key: number) =>void,
    orderList: OrderList,
    setOrderList: React.Dispatch<React.SetStateAction<OrderList>>
}

//Return the accordion body of an accordion item
export const OrderListBody = ({
    orderDetail,
    itemKey, 
    handleRemoveButton,
    orderList,
    setOrderList
}: Props) =>{
    
    const order_string: string[] = orderDetail.order_string;
    const [quantity, setQuantity] = useState<string>("");

    useEffect (() =>{
        setQuantity(String(orderList.list.get(itemKey)!.orderDetail.quantity))        
    }, [orderList])
    
    
    //Handle copying the button to the clipboard
    const handleCopyButton = () =>{
        
        navigator.clipboard.writeText(order_string.join("\n"))
        .then(() =>{
            //********* Can do something here if we need
        })
        .catch(error =>{
            console.error("Failed to copy text: ", error);
        })
    }

    //Handle a text box losing focus
    const handleBlur = (key: number, value: string) =>{
        processTextValue(key, value);
    }

    //Process a text value if it is valid, otherwise revert it
    const processTextValue = (key: number, value: string) =>{
        
        //Get a copy of the order list
        const order_list = getOrderListCopy(orderList);
        
        //Get the detail associated with this accordion item
        const detail = order_list.list.get(key)!.orderDetail;
        detail.quantity = Number(value);
        detail.order_string = getOrderString(detail);
        
        //Set the order list
        setOrderList(order_list);        
    }

    //Handle a key down event on a text control
    const handleKeyDown = (key: number, value: string, event: React.KeyboardEvent<HTMLElement>) =>{        
        if(event.key === 'Enter'){
            processTextValue(key, value);
        }
    }

    //Handle when a text change is made
    const handleTextChange = (key: number, value: string) =>{
        
        const value_number = Number(value);
        if(!isNaN(value_number)){
            setQuantity(value);
        }
    }

    return(
        <Fragment>
            <div className="d-flex justify-content-end align-items-end">                
                <Form.Label className = "me-2">Quantity</Form.Label>
                <Form.Control 
                    type="text"
                    style={{width: "60px"}}
                    className="me-2"
                    value={quantity}
                    onChange={(event) => handleTextChange(itemKey, event.target.value)}
                    onBlur={(event) => handleBlur(itemKey, event.target.value)}
                    onKeyDown={(event) => handleKeyDown(itemKey, event.currentTarget.value, event)}
                />

                <Button
                    onClick={ () =>handleRemoveButton(itemKey)}
                >
                    Remove
                </Button>
                <Button
                    onClick={handleCopyButton}
                    style={{marginLeft: '0.5rem'}}
                >
                    Copy To Clipboard
                </Button>
            </div>
            {order_string.map((line, index) =>(
                <Fragment key={index}>
                    {line}
                    <br />
                </Fragment>
            ))}            
        </Fragment>
    )
}