import {RenderData} from "../render_data/render_data";
import {Line} from "../window/window_fragment";

//Draw an error around conflicting posts
export const maskErrorPost = (renderData: RenderData) =>{    
    
    //Iterate through all the post errors
    renderData.error.post.model.forEach(model =>{
        
        let post_width =0;

        //Determine the width of the error line, based on if the error is a post or a mull
        if(model.is_post){
            post_width = renderData.error.post.post_width;
        }
        else{
            post_width = renderData.error.post.mull_width;
        }

        drawErrorLine(post_width, model.line, renderData);
    })

}

//Highlight sides marked as errors
export const maskErrorSide = (renderData: RenderData) =>{    
    //Iterate through all the line sides that were marked as errors
    renderData.error.side.line.forEach(line =>{
        drawErrorLine(renderData.error.side.line_width, line, renderData);
    })
}

//Draw a circle around any angle that is marked as an error
export const maskErrorAngle = (renderData: RenderData) =>{
    const context = renderData.viewport.context;
    context!.save();
    context!.strokeStyle = renderData.error.color;
    //context!.fillStyle = renderData.error.color;
    context!.lineWidth = renderData.error.angle.line_width;


    //Iterate through all the vertices that were marked as errors
    renderData.error.angle.vertex.forEach(vertex =>{        
        context!.beginPath();
        context!.arc(
            vertex.x, 
            vertex.y,
            renderData.error.angle.radius,
            0,
            2 * Math.PI
        );
        context!.stroke();
        //context!.fill();
    })

    context!.restore();
}

//Draw a line as an error
const drawErrorLine = (error_width: number, line: Line, renderData: RenderData) =>{
    const context = renderData.viewport.context;
    
    //Draw an error line according to a line
    context!.save();
    context!.beginPath();
    context!.strokeStyle = renderData.error.color;    
    context!.lineWidth = error_width;
    context!.moveTo(line.x1, line.y1);
    context!.lineTo(line.x2, line.y2);
    context!.stroke();
    context!.restore();        
}