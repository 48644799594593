import {setViewPortSize} from "../../render/render_components/viewport/functions_viewport";
import {RenderData} from "../../render/render_components/render_data/render_data";
import {ConstrainValues} from "../../constraints/constrain_value";
import {constrainProductLine, constrainOverallSize} from "../../constraints/functions_constrain";
import {defaultPatio} from "./functions_default_patio";


const WindowSize = async (
    form_object: string,
    object_value: string,     
    renderData: RenderData,
    constrainValues: ConstrainValues
) =>{  
    
    switch(form_object){
        case "callout":
            renderData.data.is_callout = true;
            //This is for checking patio doors; they have different constraints based on callout or net
            if(renderData.data.product_line.id >=0){
                constrainProductLine(renderData.data.product_line.value, constrainValues, renderData);
            }
        break;
        case "special":
            renderData.data.is_callout = false;
            //This is for checking patio doors; they have different constraints based on callout or net
            if(renderData.data.product_line.id >=0){
                constrainProductLine(renderData.data.product_line.value, constrainValues, renderData);
            }
        break;
        case "size_callout":            
            //Calculate the size of the window in inches, from callout size
            const callout = Number(object_value);            
            renderData.data.callout = callout;
            
            //Divide the callout into values; assume anyting over 10000 is part of the x value
            let current = callout;
            const x1 = Math.floor(current / 1000);                       
            current -= x1 * 1000;          
            
            const x2 = Math.floor(current / 100);
            current -= x2 * 100;

            const y1 = Math.floor(current / 10);
            current -= y1 * 10;

            const y2 = current;

            const width = (x1 * 12) + x2;
            const height = (y1 * 12) + y2;
            
            renderData.viewport.inch_width = width;
            renderData.viewport.inch_height = height;
        break;
        case "size_width":            
            renderData.viewport.inch_width = Number(object_value);
        break;
        case "size_height":
            renderData.viewport.inch_height = Number(object_value);
        break;
    }
    
    if(renderData.viewport.inch_width >0 && renderData.viewport.inch_height >0){        
        setViewPortSize(renderData);

        defaultPatio(renderData);
        
        constrainOverallSize(renderData, constrainValues);
    }
    
}

export default WindowSize