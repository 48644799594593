import {setGridX, setGridY} from "../../render/render_components/viewport/functions_viewport";
import {RenderData} from "../../render/render_components/render_data/render_data";
import {PanelFragment} from "../../render/render_components/panel/panel_fragment";
import {WindowFragment} from "../../render/render_components/window/window_fragment";

const WindowGrid = (
    id_object: string, 
    form_object: string, 
    object_value: string, 
    key_id: number,     
    renderData: RenderData,    
) =>{
    
    const window = renderData.window[renderData.active_fragment_id];
    const panel = window.panel[window.active_panel_id];

    //Grid Type
    if(id_object === "group_grid_type"){                        
        panel.data.grid.grid_type.value = object_value;
        panel.data.grid.grid_type.id = key_id;        
    }

    //DVLs
    if(form_object === "dvl_width"){
        if(object_value){                    
            panel.data.grid.dvl_width = Number(object_value);
            if(renderData.active_fragment_id >=0){
                setGridX(renderData, Number(object_value), panel);
            }                                        
        }
        else{
            panel.data.grid.dvl_width = 0;       
        }        
    }
    if(form_object === "dvl_height"){
        if(object_value){
            panel.data.grid.dvl_height = Number(object_value);
            if(renderData.active_fragment_id >=0){
                setGridY(renderData, Number(object_value), panel);
            }                                     
        }
        else{
            panel.data.grid.dvl_height = 0;
        }
        
    }
    
    panel.data.grid.has_grid = hasGrid(panel);

    if(id_object === "apply_all"){
        if(object_value === "false"){
            renderData.all_window.apply_all_grid = false;
        }   
        else{
            renderData.all_window.apply_all_grid = true;
        }
    }
    applyAllValue(renderData, panel);
    
    
    if(id_object === "grid_pattern"){
        panel.data.grid.grid_pattern.id = key_id;
        panel.data.grid.grid_pattern.value = object_value;
    }
}

//Apply the grid value to all panels
const applyAllValue = (renderData: RenderData, panel: PanelFragment) =>{    
    if(renderData.all_window.apply_all_grid){
        //Iterate through all the windows
        renderData.window.forEach((window) =>{
            
            switch(panel.data.grid.grid_pattern.value){
                case "No Grid":
                case "Diagram":
                    applyAll(window, panel);
                break;
                case "Sunburst":
                break;
                case "Standard":
                    applyStandard(renderData, window, panel);
                break;
                case "Margin":
                break;                                
            }            
        })
    }
}

//Apply to all panels
const applyAll = (window: WindowFragment, panel: PanelFragment) =>{
    window.panel.forEach(current_panel =>{
        if(current_panel.id !== panel.id){
            current_panel.data.grid.grid_pattern.id = panel.data.grid.grid_pattern.id;
            current_panel.data.grid.grid_pattern.value = panel.data.grid.grid_pattern.value;
        }
    })
}

//Apply standard grids to all windows that can have a standard grid
const applyStandard = (renderData: RenderData, window: WindowFragment, panel: PanelFragment) =>{    
    switch(window.data.product_type.value){
        case "PW":
        case "XO":
        case "OX":
        case "XOX":
        case "XOO":
        case "OXO":
        case "OOX":
        case "OXXO":
        case "Single Hung":
            //Iterate through all the panels
            window.panel.forEach((current_panel) =>{
                if(current_panel.id !== panel.id){                     
                    current_panel.data.grid.dvl_width = panel.data.grid.dvl_width;
                    current_panel.data.grid.dvl_height = panel.data.grid.dvl_height;                    
                    current_panel.data.grid.has_grid = panel.data.grid.has_grid;
                    current_panel.data.grid.grid_type.id = panel.data.grid.grid_type.id;
                    current_panel.data.grid.grid_type.value = panel.data.grid.grid_type.value;                                        
                    current_panel.data.grid.grid_pattern.id = panel.data.grid.grid_pattern.id;
                    current_panel.data.grid.grid_pattern.value = panel.data.grid.grid_pattern.value;
                    setGridX(renderData, panel.mask_grid.grid_x, current_panel);
                    setGridY(renderData, panel.mask_grid.grid_y, current_panel);                    
                }
                panel.data.grid.has_grid = hasGrid(panel);
            })
        break;
    }
}

//Determine if a panel has a grid
const hasGrid = (panel: PanelFragment): boolean =>{
    if(panel.data.grid.dvl_width >0 && panel.data.grid.dvl_height >0) return true;
    
    return false;
}

export default WindowGrid;