import {RenderData} from "../../render/render_components/render_data/render_data";

const Screen = (object_value: string, renderData: RenderData) =>{
    
    const window = renderData.window[renderData.active_fragment_id];
    const panel = window.panel[window.active_panel_id];

    if(object_value === "With Screen"){        
        panel.data.has_screen = true;
    }
    else{
        panel.data.has_screen = false;
    }
    
    renderData.render_frame = true;
}

export default Screen;