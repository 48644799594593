import {RenderData} from "../../render/render_components/render_data/render_data";
import {setViewPortSize} from "../../render/render_components/viewport/functions_viewport";
import {defaultPatio} from "./functions_default_patio";

const FrameType = (object_value: string, key_id: number, renderData: RenderData) =>{
    
    if(key_id === -1 && renderData.data.frame_type.id >0){
        renderData.data.frame_type.id =0;
        renderData.data.frame_type.value ="";
    }
    else{
        renderData.data.frame_type.value = object_value;
        renderData.data.frame_type.id = key_id;

        if(renderData.data.is_callout){
            switch(object_value){
                case "Flush Fin":
                case "Nail On":
                case "NuWall":
                    renderData.viewport.inch_width -= 0.5;
                    renderData.viewport.inch_height -= 0.5;

                    //Recreate the window
                    setViewPortSize(renderData);

                    defaultPatio(renderData);

                break;
            }
        }
    }    
}

export default FrameType;