import {useState, useEffect, useRef} from "react";
import Button from 'react-bootstrap/Button';
import Form from "react-bootstrap/Form";
import {BUTTON_TYPE} from "../../../settings";
import {OrderList} from "../data/order/order_list";

interface Props{
    orderList: OrderList
}

//Display the order list in text format, so it can be copied
export const OrderText = ({orderList}: Props) =>{
    
    const [rowCount, setRowCount] = useState<number>(1); //Number of rows for the text box
    const [orderString, setOrderString] = useState<string[]>([]); //Order in string array format
    const [price, setPrice] = useState<number>(0); //Price the current order window

    const orderTextRef= useRef<HTMLTextAreaElement>(null);

    //Monitor for when the order list changes
    useEffect( () =>{
        
        //If at least one entry is in the order list
        if(orderList.selected_index >=0){            

            //Get the current selected order list and then get the order in array string format
            const order_detail = orderList.list.get(orderList.selected_index)!.orderDetail;            
            
            //Create an order string array out of the order detail
            const order_string: string[] = order_detail.order_string;

            //Set the order array string and text row count
            setOrderString(order_string);
            setRowCount(order_string.length);
            setPrice(order_detail.price * order_detail.quantity);
            
        }
        else{
            //If nothing is selected or exists, add a blank string
            const order_string = [""];
            setOrderString(order_string);
            setRowCount(order_string.length);
        }
        
    }, [orderList])

    //Copy the text are to the clipboard when the button is clicked
    const handleButtonClick = () =>{        
        
        //Try to write using the navigator clipboard
        if(navigator.clipboard.writeText){
            navigator.clipboard.writeText(orderString.join("\n"))
            .then(() =>{
                //********* Can do something here if we need
            })
            .catch(error =>{
                console.error("Failed to copy text: ", error);
            })
        }
        
        //************* This is a deprecated way of copying the text. The above code is correct, but will not work until the server is secured.
        /*
        if(orderTextRef.current){
            orderTextRef.current.select();
            
            try{
                const successful = document.execCommand('copy');
            }
            catch (error){
                console.error(error);
            }             
        }
        */       
    }
    
    return (
        <Form>
            <div className="d-flex justify-content-between align-items-center mt-2 mb-2">                
                <h5 className="m-0">Order Text</h5>      
                <div className="d-flex align-items-center ml-auto">                    
                    <Form.Label className="me-2">Price</Form.Label>
                    <Form.Control 
                        type="text"
                        value={price.toFixed(2)}
                        style={{width: "120px"}}
                        className="me-2"
                        readOnly
                    />
                    <Button
                        onClick={handleButtonClick}
                    >
                        Copy to Clipboard
                    </Button>
                </div>
            </div>            
            <Form.Control
                as="textarea"
                rows={rowCount}
                value={orderString.join("\n")}
                readOnly
                ref={orderTextRef}
            />
        </Form>
    )
}