import {
    ConstrainValues, 
    ConstrainType, 
    ConstrainControlProperty,
    ConstrainParameter
} from "./constrain_value";
import {
    constrainWindow, 
    constrainPatioDoor,
    constrainStack,
    constrainXOP
} from "./layout_constrain";
import {
    constrainSMF, 
    constrainTMF, 
    constrainMF2,
    constrainMF2Door,
    constrainVinylDoor
} from "./product_line_constrain";
import {RenderData} from "../render/render_components/render_data/render_data";
import {constrainBlackCap, constrainGenericColor} from "./frame_color_constrain";
import {constrainAdvanced, constrainSplit, constrainXOPDimension} from "./xop_properties_constraint";
import {constrainSelect} from "./select_constrain";
import {
    constrainPanelHorizontal, 
    constrainPanelVertical,
    constrainStraightNoGrid,
    constrainStraightGrid,
    constrainGridOnly,
    constrainWindowOnly    
} from "./product_type_constrain";
import {constrainOverallDimension} from "./window_size_constraints";
import {validateWindows} from "./functions_validate_window";

//Constrain values, based on the overall size 
export const constrainOverallSize = (renderData: RenderData, constrainValues: ConstrainValues) =>{
    constrainOverallDimension(renderData, constrainValues);
}

//Constrain values, based on window and or panel selections
export const constrainActiveWindow = (renderData: RenderData, constrainValues: ConstrainValues) =>{    
    constrainSelect(renderData, constrainValues);
}

//Constrain values, based on product type
export const constrainProductType = (description: string, constrainValues: ConstrainValues, renderData: RenderData) =>{
    resetWindowControls(constrainValues);        
    
    const control = constrainValues.control;
    setControl(control.window, false, false);
    setControl(control.grid, true, true);
    setControl(control.screen, true, true);
    
    switch(description){
        case "XO":
        case "OX":
        case "XOO":
        case "OXO":
        case "OOX":
        case "XOX":
        case "OXXO":
            constrainPanelHorizontal(constrainValues);            
        break;
        case "Single Hung":
            constrainPanelVertical(constrainValues);            
        break;
        case "Peak":
        case "L Rake":     
        case "R Rake":
            constrainStraightNoGrid(constrainValues);
        break;
        case "Straight Leg Arch":
        case "L Straight Leg Arch":
        case "R Straight Leg Arch":
            constrainStraightGrid(constrainValues);
        break;
        case "Factory Arch":
            constrainGridOnly(constrainValues);
        break;
        case "PW":
            constrainWindowOnly(constrainValues);
        break;
        default:
            setControl(control.window, true, true);
        break;
    }

    validateWindows(constrainValues, renderData);
}

//Constrain layouts according which button was selected (header button)
export const constrainLayout = (buttonName: string, constrainValues: ConstrainValues) =>{
    const control = constrainValues.control;
    setControl(control.configurator, false, false);
    setControl(control.xop, true, true);
    setControl(control.window, true, true);
    
    constrainValues.control.layout = buttonName;    
    
    resetAllConstraints(constrainValues);
    switch (buttonName){
        case "Patio Door":
            constrainPatioDoor(constrainValues);
        break;
        case "Stack/Mull":
            constrainStack(constrainValues);
        break;
        case "XOP":
            constrainXOP(constrainValues);
        break;
        case "Window":            
            constrainWindow(constrainValues);
        break;
    }
}

//Constrain values, based on product line selections
export const constrainProductLine = (description: string, constrainValues: ConstrainValues, renderData: RenderData) =>{    
    
    /* Not sure we need this; I would rather move it to the start of the whole process if need be.
    resetConstrainType(constrainValues.frame_type);
    resetConstrainType(constrainValues.frame_color);
    //resetVisibleConstrainType(constrainValues.product_type);
    resetConstrainType(constrainValues.glass);
    resetConstrainType(constrainValues.grid_type);
    resetConstrainType(constrainValues.screen);
    */

    switch (description){
        case "Vinyl Door":
            constrainVinylDoor(constrainValues, renderData);
        break;
        case "Patio Door":
            constrainMF2Door(constrainValues, renderData);
        break;
        case "SMF Vinyl":
            constrainSMF(constrainValues);
        break;
        case "TMF Vinyl":
            constrainTMF(constrainValues);
        break;
        case "MF-2":
            constrainMF2(constrainValues);
        break;        
    }
}

//Constrain values, based on frame color selection
export const constrainFrameColor = (description: string, constrainValues: ConstrainValues) =>{
    resetConstrainType(constrainValues.grid_type);
    
    switch (description){
        case "Almond":
        case "Bronze":
        case "White":
            constrainGenericColor(constrainValues);
        break;
        case "Black Capstock":
            constrainBlackCap(constrainValues);
        break;        
    }
}

export const constrainXOPProperties = (
    description: string, 
    value: string, 
    constrainValues: ConstrainValues, 
    renderData: RenderData
) =>{
    switch (description){
        case "Advanced":
            constrainAdvanced(value, constrainValues);
        break;
        case "split_horizontal":
            constrainSplit(renderData, constrainValues);
        break;
        case "split_vertical":
            constrainSplit(renderData, constrainValues);
        break;
        case "input_top":
        case "input_bottom":
        case "input_left":
        case "input_right":
        case "input_width":
        case "input_height":
            constrainXOPDimension(constrainValues, renderData);
        break;
    }
}

//Reset all the constraints to their original values
const resetAllConstraints = (constrainValues: ConstrainValues) =>{
    resetConstrainType(constrainValues.product_line);
    resetConstrainType(constrainValues.frame_type);
    resetConstrainType(constrainValues.frame_color);
    resetConstrainType(constrainValues.product_type);
    resetConstrainType(constrainValues.glass);
    resetConstrainType(constrainValues.grid_type);   
    resetConstrainType(constrainValues.screen);
    resetConstrainType(constrainValues.option);

    resetAllControlConstraints(constrainValues);
}

//Reset all the constrains for controls
const resetAllControlConstraints = (constrainValues: ConstrainValues) =>{
    resetXOPControls(constrainValues);
    resetWindowControls(constrainValues);
}

//Reset XOP constrol constraints
const resetXOPControls = (constrainValues: ConstrainValues) =>{
    const control = constrainValues.control;
    
    setControl(control.xop_advanced, true, true);
    setControl(control.xop_width, true, true);
    setControl(control.xop_height, true, true);
    setControl(control.xop_top, true, true);
    setControl(control.xop_bottom, true, true);
    setControl(control.xop_left, true, true);
    setControl(control.xop_right, true, true);
}

//Reset window property control constraints
const resetWindowControls = (constrainValues: ConstrainValues) =>{
    const control = constrainValues.control;

    setControl(control.window_width, true, false);
    setControl(control.window_height, true, false);
    setControl(control.window_open_direction, true, false);
    setControl(control.window_second_height, true, false);
}

//Reset all constraints for a specific type of constrain
export const resetConstrainType = (constrainType: ConstrainType) =>{    
    //Iterate through all the parameters in the constrain type
    constrainType.parameter.forEach(parameter =>{
        parameter.is_disabled = false;
        parameter.is_hidden = false;
    })
}

//Reset constraints for visible constrain types
export const resetVisibleConstrainType = (constrainType: ConstrainType) =>{
    //Iterate through all the parameters in the constrain type and set only visible constraints
    constrainType.parameter.forEach(parameter =>{
        if(!parameter.is_hidden){
            parameter.is_disabled = false;
        }
    })
}

//Set the constraints for a parameter
export const setConstraint = (constrainType: ConstrainType, description: string, is_disabled: boolean, is_hidden: boolean) =>{    
    const parameter = getConstraint(constrainType, description);

    //Set if a parameter is hidden or not
    parameter.is_disabled = is_disabled;
    parameter.is_hidden = is_hidden;    
}

//Set a control's enable, only if it is visible
export const setVisibleConstraint = (constrainType: ConstrainType, description: string, is_disabled: boolean) =>{
    const parameter = getConstraint(constrainType, description);
    
    if(!parameter.is_hidden){
        parameter.is_disabled = is_disabled;
    }
}

//Get a constrain values based on its description and return it
export const getConstraint = (constrainType: ConstrainType, description: string): ConstrainParameter =>{
    const id: number = constrainType.string_id.get(description)!;    

    return constrainType.parameter.get(id)!;
}

//Set the constraints for visible controls
export const setVisibleControl = (control_type: ConstrainControlProperty, is_disabled: boolean) =>{
    if(!control_type.is_hidden){
        control_type.is_disabled = true;
    }
}

//Set the constrains for a control
export const setControl = (control_type: ConstrainControlProperty, is_disabled: boolean, is_hidden: boolean) =>{
    control_type.is_disabled = is_disabled;
    control_type.is_hidden = is_hidden;
}





