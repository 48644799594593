import {useState, useEffect} from "react";
import Button from 'react-bootstrap/Button';
import Form from "react-bootstrap/Form";
import {OrderRequest} from "../data/order/order_request";
import {BUTTON_TYPE} from "../../../settings";
import {OrderList, addOrder} from "../data/order/order_list";
import {getOrderListCopy} from "../data/copy_data";
import {RenderData} from "../render/render_components/render_data/render_data";
import {getErrorString} from "../render/render_components/render_data/error_data";

interface Props{
    renderData: RenderData,
    orderRequest: OrderRequest,
    orderList: OrderList,
    setOrderList: React.Dispatch<React.SetStateAction<OrderList>>
}

export const AddOrderControl = ({
    renderData,
    orderRequest,
    orderList,
    setOrderList
}: Props) =>{
    
    const [price, setPrice] = useState<number>(0);    
    const [errorString, setErrorString] = useState<string[]>([]);

    //Update the price when a new one is set
    useEffect (() =>{
        setPrice(orderRequest.price);
    }, [orderRequest])

    //Monitor for changes in the error object
    useEffect(() =>{
        setErrorString(getErrorString(renderData.error));
    }, [renderData.error]);

    //Handle the button click for adding an order
    const handleButtonClick = () =>{
        
        //Only add the order if a price was found
        if(price >0){
            //Get a copy of the list, add the current order and set it
            const list = getOrderListCopy(orderList);                           
            addOrder(renderData, orderRequest, list);        
            setOrderList(list);
        }        
    }

    //Determine if the add order button should be enabled
    const disableAddOrder = (): boolean =>{        
        if(price === 0) return true;
        if(errorString.length >0) return true;        
        return false;
    }

    return (
        <Form>
            {errorString.length > 0 && (
                <Form.Group className="mt-2">                    
                    <Form.Control
                        as="textarea"
                        rows={errorString.length}
                        value={errorString.join("\n")}
                        readOnly
                        style={({color: "rgba(200,0,0,1)"})}
                    />
                </Form.Group>
            )}
            
            <div className="d-flex mt-2 align-items-end justify-content-end">
                <Form.Label className="me-2">Price</Form.Label>
                <Form.Control 
                    type="text"
                    value={price.toFixed(2)}
                    style={{width: "120px"}}
                    readOnly
                />
                <Button
                    variant={BUTTON_TYPE}
                    style={{marginLeft: '0.5rem'}}
                    onClick={handleButtonClick}
                    disabled={disableAddOrder()}                    
                >
                    Add to Order
                </Button>
            </div>
        </Form>
    );
}